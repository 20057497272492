import { Button } from 'antd'
import Link from 'next/link'
import { URL_TEMPLATE } from 'src/libs/constant'

const HeaderTutorRegisterSchedule = () => {
  return (
    <>
      <div className='d-flex align-items-center'>
        <div
          className='header-layout__title'
          style={{
            fontWeight: 400,
            fontSize: 24,
            fontFamily: 'inherit',
            lineHeight: '100%',
            letterSpacing: '0',
            textTransform: 'uppercase',
          }}
        >
          <h4>Lịch dạy cần đăng ký trước ngày 26 hàng tháng</h4>
        </div>

        <Link href={`${URL_TEMPLATE.CALENDAR_TEACHER_BOOK}`}>
          <button className='header-button__use-immediately'>Đăng kí ngay</button>
        </Link>
      </div>
    </>
  )
}

export default HeaderTutorRegisterSchedule
