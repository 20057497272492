import { get, post, put } from '../libs/query'
import { API_TEMPLATE } from '../libs/constant'
import { formatUrl } from '../libs/url'

const centerApi = {
  centerList ({ criteria, page, size }, queryString) {
    const path = API_TEMPLATE.CENTER_LIST
    return post(path, { criteria, page, size, sortOrders: queryString?.sortOrders })
  },
  createCenter (payload) {
    return post(`${API_TEMPLATE.CENTER_CREATE}`, payload)
  },
  updateCenter (id, payload) {
    const path = formatUrl(API_TEMPLATE.CENTER_UPDATE, [{ key: 'id', value: id }])
    return put(path, payload)
  },
  changeStatus (id, payload) {
    const path = formatUrl(API_TEMPLATE.CENTER_CHANGE_STATUS, [{ key: 'id', value: id }])
    return put(path, payload)
  },
  getListCenter (payload) {
    const path = API_TEMPLATE.CENTER_LIST
    return post(path, payload)
  },
  getListCenterNotRole () {
    return get(`${API_TEMPLATE.GET_CENTER}`)
  },
  getDetail (id) {
    const path = formatUrl(API_TEMPLATE.CENTER_DETAIL, [{ key: 'id', value: id }])
    return get(path)
  }
}
export default centerApi
