import React, { useEffect, useState } from 'react'
import { Layout } from 'antd'
import styled from 'styled-components'
import { ComponentValidator } from '../auth/Validator'
import { SidebarMenu } from './SidebarMenu'
import { AppHeader } from './Header'
import { isLoggedIn, logout } from 'src/libs/browser'
import { useRouter } from 'next/router'
import { CATEGORY_TYPE, URL_TEMPLATE } from 'src/libs/constant'
import Link from 'next/link'
import { setCategories } from '../../redux/actions/categoriesAction'
import commonApi from '../../api/commonApi'
import { useDispatch } from 'react-redux'
import calendarConfigApi from '../../api/calendarApi'
import centerApi from '../../api/centerApi'

const { Content, Footer, Sider } = Layout

export function AppLayout (props) {
  const dispatch = useDispatch()
  const [sidebarIsCollapsed, setSidebarCollapsed] = useState(false)
  const [collapsedWidth] = useState(200)
  const [date, setDate] = useState(new Date())
  const router = useRouter()

  useEffect(() => {
    if (!isLoggedIn() && router.pathname !== URL_TEMPLATE.SYSTEM_LOGIN) {
      logout()
      const url = router.asPath
      const query = url && url !== '/' && `?from=${encodeURIComponent(url)}`
      router.replace(`${URL_TEMPLATE.SYSTEM_LOGIN}${query || ''}`)
    }
  }, [])

  useEffect(() => {
    let timeout
    if (isLoggedIn()) {
      timeout = setTimeout(() => {
        console.log(`Ready ${new Date()}`)
        setDate(new Date())
        fetchData()
      }, Math.floor(Math.random() * 10 + 1) * 10000)
    } else {
      timeout = setTimeout(() => {
        setDate(new Date())
      }, 1000)
    }
    return () => {
      clearTimeout(timeout)
    }
  }, [date])

  async function fetchData () {
    console.log('(fetchData)')
    const tempCategories = {}

    const crmPackageResponse = await commonApi.getCrmPackage()
    tempCategories[CATEGORY_TYPE.CRM_PACKAGE] = await crmPackageResponse.data

    const classTimeSlotResponse = await calendarConfigApi.findAllTimeSlot()
    tempCategories[CATEGORY_TYPE.CLASS_TIME_SLOT] = await classTimeSlotResponse.data

    const deviceResponse = await commonApi.getDevice()
    tempCategories[CATEGORY_TYPE.DEVICE] = await deviceResponse.data

    const gradeResponse = await commonApi.getGrade()
    tempCategories[CATEGORY_TYPE.GRADE] = await gradeResponse.data

    const levelResponse = await commonApi.getLevel()
    tempCategories[CATEGORY_TYPE.LEVEL] = await levelResponse.data

    const provinceResponse = await commonApi.getProvince()
    tempCategories[CATEGORY_TYPE.PROVINCE] = await provinceResponse.data

    const regionResponse = await commonApi.getRegion()
    tempCategories[CATEGORY_TYPE.REGION] = await regionResponse.data

    const subjectResponse = await commonApi.getSubject()
    tempCategories[CATEGORY_TYPE.SUBJECT] = await subjectResponse.data

    const studyProgramResponse = await commonApi.getStudyProgram()
    tempCategories[CATEGORY_TYPE.STUDY_PROGRAM] = await studyProgramResponse.data

    const violationLevelResponse = await commonApi.getViolationLevel()
    tempCategories[CATEGORY_TYPE.VIOLATION_LEVEL] = await violationLevelResponse.data

    const centerResponse = await centerApi.getListCenterNotRole()
    tempCategories[CATEGORY_TYPE.CENTER] = await centerResponse.data

    dispatch(setCategories(tempCategories))
  }

  if (router.pathname === URL_TEMPLATE.SYSTEM_LOGIN) {
    return props.children
  }

  const headerLogo = sidebarIsCollapsed && collapsedWidth === 0 && (
    <CustomLogo className='logo'>
      <img src='/logo.svg' alt='logo' style={{ width: 'auto', height: 60 }}/>
    </CustomLogo>
  )
  return (
    <ComponentValidator visible={isLoggedIn()} loading>
      <Layout>
        <Sider
          style={{
            overflowY: 'auto',
            overflowX: 'hidden',
            height: 'calc(100vh - 48px)',
            position: 'fixed',
            left: 0,
            zIndex: 100
          }}
          collapsedWidth={50}
          collapsible
          breakpoint='md'
          onCollapse={(isCollapsed) => {
            setSidebarCollapsed(isCollapsed)
          }}
        >
          <div
            style={{
              minHeight: 60,
              padding: sidebarIsCollapsed ? '15px 0 17px 0' : '17px 17px',
              transition: 'all 0.2s ease-out'
            }}
          >
            <Link href={URL_TEMPLATE.SYSTEM_DASHBOARD}>
              <img
                src='/logo.svg'
                alt='logo'
                style={{
                  width: 'auto',
                  height: sidebarIsCollapsed ? 45 : 60,
                  transition: 'all 0.2s ease-out',
                  cursor: 'pointer'
                }}
              />
            </Link>
          </div>
          <SidebarMenu roles={[]} sidebarIsCollapsed={sidebarIsCollapsed}/>
        </Sider>
        <Layout
          className='site-layout'
          style={{
            marginLeft: sidebarIsCollapsed ? 50 : 200,
            transition: 'margin-left 0.2s'
          }}
        >
          <AppHeader logo={headerLogo}/>
          <Content
            style={{
              margin: '24px 16px 0',
              overflow: 'initial',
              minHeight: '80vh'
            }}
          >
            {props.children}
          </Content>
          <Footer>
            <div className='float-right d-none d-sm-inline'>
              &quot;Customer first&quot;
            </div>
            <strong>
              Copyright &copy; 2021{' '}
              <a href='https://edupia.vn' className='mx-3'>
                edupiatutor.vn
              </a>
            </strong>
            All rights reserved.
          </Footer>
        </Layout>
      </Layout>
    </ComponentValidator>
  )
}

const CustomLogo = styled.div`
    &.logo {
        float: left;
        width: 120px;
        height: 31px;
        margin: 16px 24px 16px 0;
    }
`
