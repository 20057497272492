import { Layout, Dropdown, Menu, Avatar, Modal, Button, Carousel } from 'antd'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import React, { useEffect, useState } from 'react'

import ChangePasswordForm from '../profile/ChangePasswordForm'
import { getRefreshToken, getRole, getToken, getUser, hasRole, logout } from '../../libs/browser'
import { URL_TEMPLATE, USER_ROLES_ENUM } from '../../libs/constant'
import authApi from '../../api/authApi'
import { ComponentValidator } from '../auth/Validator'
import { useModalState } from 'src/hooks/useModalState'
import { hasRequiredItem } from 'src/libs/util'
import { useEventTeacherDayVN } from 'src/hooks/useEventTeacherDayVN'
import userApi from 'src/api/userApi'
import { UserOutlined } from '@ant-design/icons'
import { useGetData } from 'src/hooks/useGetData'
import { EVENT_CHANGE_AVATAR } from '../manage/user/edit/AvatarProfile'
import useEventListener from 'src/hooks/useEventListener'
import HeaderRemindTutor from './HeaderRemindTutor'
import HeaderTutor from './HeaderTutor'
import HeaderTeacherDay from './HeaderTeacherDay'
import HeaderTutorRegisterSchedule from './HeaderTutorRegisterSchedule'
// import { Carousel } from 'react-bootstrap'

function handleLogout() {
  authApi.logout({
    accessToken: getToken(),
    refreshToken: getRefreshToken(),
  })
  logout()
  setTimeout(() => {
    window.open(URL_TEMPLATE.SYSTEM_LOGIN, '_self')
  }, 100)
}

const { Header } = Layout
export function AppHeader({ logo }) {
  const { handleClose, handleOpen, isModalVisible } = useModalState()
  const { t } = useTranslation()
  const user = getUser()
  const [currentTime, setCurrentTime] = useState(null)
  const isTeacher = hasRole([USER_ROLES_ENUM.EMPLOYEE_TEACHER]) && getRole().length === 1

  const openListener = ({ detail }) => {
    setCurrentTime(detail?.time)
  }

  const { data: userDetail } = useGetData(
    userApi.detail,
    user?.userId,
    { isDisabled: !user?.userId },
    [currentTime],
  )
  useEventListener(EVENT_CHANGE_AVATAR, openListener, document)
  const menu = (
    <Menu>
      <ComponentValidator visible={hasRequiredItem(user?.userRoles || [], ['employee-teacher'])}>
        <Menu.Item key={1}>
          <Link href={`${URL_TEMPLATE.SYSTEM_USER_INFORMATION}`}>
            <a style={{ textDecoration: 'none' }}>{t('common.label.user_information')}</a>
          </Link>
        </Menu.Item>
      </ComponentValidator>
      <Menu.Item onClick={handleOpen} key={2}>
        {t('common.label.change_password')}
      </Menu.Item>
      <Menu.Item onClick={handleLogout} key={3}>
        {t('common.label.logout')}
      </Menu.Item>
    </Menu>
  )
  const { isDisplayEvent, eventLink, description } = useEventTeacherDayVN()

  if (!isTeacher) {
    return (
      <Header
        className={`site-layout-background d-inline-flex justify-content-${
          logo ? 'between' : 'end'
        } align-items-center`}
      >
        {logo}
        <span>
          <Dropdown overlay={menu} placement='bottomLeft'>
            {userDetail?.url ? (
              <Avatar
                className='mx-2'
                src={userDetail?.url && `https://static.edupia.vn/${userDetail?.url}`}
              />
            ) : (
              <Avatar className='mx-2' icon={<UserOutlined />} />
            )}
          </Dropdown>
          <ComponentValidator visible={!!user}>
            {user?.userName && (
              <Link href={URL_TEMPLATE.SYSTEM_PROFILE} className='mx-2'>
                {user.userName}
              </Link>
            )}
          </ComponentValidator>
          <Modal
            onCancel={handleClose}
            visible={isModalVisible}
            destroyOnClose
            footer={null}
            title={t('common.label.change_password')}
            style={{ minWidth: 350 }}
          >
            <ChangePasswordForm closeModal={handleClose} />
          </Modal>
        </span>
      </Header>
    )
  }

  return (
    <Carousel dots={false} autoplay={true}  autoplaySpeed={5000} >
       <Header
          className={`site-layout-background d-inline-flex justify-content-${
            logo ? 'between' : 'end'
          } align-items-center p-0`}
          style={{width: "100%"}}
        >
          <div className='header-layout'>
            {/* Thi tháng */}
            <HeaderTutor />



            <span className='mr-4'>
              <Dropdown overlay={menu} placement='bottomLeft'>
                {userDetail?.url ? (
                  <Avatar
                    className='mx-2'
                    src={userDetail?.url && `https://static.edupia.vn/${userDetail?.url}`}
                  />
                ) : (
                  <Avatar className='mx-2' icon={<UserOutlined />} />
                )}
              </Dropdown>
              <ComponentValidator visible={!!user}>
                {user?.userName && (
                  <Link href={URL_TEMPLATE.SYSTEM_PROFILE} className='mx-2'>
                    <span style={{ color: 'white', fontWeight: '500' }}>{user.userName}</span>
                  </Link>
                )}
              </ComponentValidator>
              <Modal
                onCancel={handleClose}
                visible={isModalVisible}
                destroyOnClose
                footer={null}
                title={t('common.label.change_password')}
                style={{ minWidth: 350 }}
              >
                <ChangePasswordForm closeModal={handleClose} />
              </Modal>
            </span>
          </div>
        </Header>
        {new Date().getDate() < 27 && <Header
          className={`site-layout-background d-inline-flex justify-content-${
            logo ? 'between' : 'end'
          } align-items-center p-0`}
          style={{width: "100%"}}
        >
          <div className='header-layout'>
            {/* Thi tháng */}
            <HeaderTutorRegisterSchedule />


            <span className='mr-4'>
              <Dropdown overlay={menu} placement='bottomLeft'>
                {userDetail?.url ? (
                  <Avatar
                    className='mx-2'
                    src={userDetail?.url && `https://static.edupia.vn/${userDetail?.url}`}
                  />
                ) : (
                  <Avatar className='mx-2' icon={<UserOutlined />} />
                )}
              </Dropdown>
              <ComponentValidator visible={!!user}>
                {user?.userName && (
                  <Link href={URL_TEMPLATE.SYSTEM_PROFILE} className='mx-2'>
                    <span style={{ color: 'white', fontWeight: '500' }}>{user.userName}</span>
                  </Link>
                )}
              </ComponentValidator>
              <Modal
                onCancel={handleClose}
                visible={isModalVisible}
                destroyOnClose
                footer={null}
                title={t('common.label.change_password')}
                style={{ minWidth: 350 }}
              >
                <ChangePasswordForm closeModal={handleClose} />
              </Modal>
            </span>
          </div>
        </Header>}
    </Carousel>
  )
}
