import { Button } from 'antd'
import Link from 'next/link'
import { URL_TEMPLATE } from 'src/libs/constant'

const HeaderTutor = () => {
  return (
    <>
      <div className='d-flex align-items-center'>
        <div className='header-layout__title'>
          <h4>Lịch dạy hôm nay</h4>
          <div>
            <p className='header-description'>
              Chuẩn bị bài trước buổi học để nâng cao
            </p>
            <p className='header-description'>
              chất lượng giảng dạy, tiến bộ học sinh
            </p>
          </div>
        </div>
        <div className='header-layout__description'>
          <ul className='pl-2'>
            <li>Xem nhanh lịch dạy </li>
            <li>Xem bài tập về nhà</li>
          </ul>
          <ul>
            <li>Xem thông tin buổi học</li>
            <li>Xem nội dung bài giảng</li>
          </ul>
        </div>
        <Link href={`${URL_TEMPLATE.SESSION_TEACHING_SCHEDULE}`}>
          <button className='header-button__use-immediately'>
            Dùng ngay
          </button>
        </Link>
      </div>
    </>
  )
}

export default HeaderTutor
